import {useContext, useEffect} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import QRCode from 'react-qr-code';
import {motion} from 'framer-motion';
import {useAtom} from 'jotai';
import axios from 'axios';

import {SocketContext} from '../context/socket';
import {
  currentGameStatus,
  currentSpinState,
  generateGameState,
  hostState,
  joinedRoomState,
  lobbyFullState,
  lobbyIdState,
  lobbySizeState,
  newGameStatusState,
  personalisationDetailsState,
  playerNumberState,
  userDisconnectionState,
  userLeaveState
} from '../state/default_state';
import {makeid} from '../App';

export const Lobby = props => {
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const location = useLocation();
  let {lobbynumber, sharerid} = useParams();
  const [isHost, setIsHost] = useAtom(hostState);
  const [lobbySize] = useAtom(lobbySizeState);
  const [lobbyFull, setLobbyFull] = useAtom(lobbyFullState);
  const [userGenerateGame, setuserGenerateGame] = useAtom(generateGameState);
  const [userDisconnection, setUserDiconnection] = useAtom(userDisconnectionState);
  const [userLeave, setUserLeave] = useAtom(userLeaveState);
  const [personalisationDetails, setPersonalisationDetails] = useAtom(personalisationDetailsState);
  const [lobbyId, setLobbyId] = useAtom(lobbyIdState);
  const [playerNumber] = useAtom(playerNumberState);
  const [hasJoinedRoom, setHasJoinedRoom] = useAtom(joinedRoomState);
  const [, setCurrentSpin] = useAtom(currentSpinState);
  const [gameStatus, setGameStatus] = useAtom(currentGameStatus);
  const [newGameStatus, setNewGameStatus] = useAtom(newGameStatusState);

  let params = new URL(document.location).searchParams;
  let shareLobby = params.get('lobby');

  useEffect(() => {
    if (sharerid) {
      axios(`https://stage-christmas.kerve.co.uk/api/v1/game.lookup?identity=${lobbynumber}&sharer=${sharerid}`)
        .then(response => {
          setPersonalisationDetails(response.data.data);

          setCurrentSpin(response.data.data.current_spin ? response.data.data.current_spin : 1);

          if (response.data.data.game_status === 'completed') {
            setGameStatus(response.data.data.game_status);
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else if (lobbynumber) {
      //sharer=
      axios(`https://stage-christmas.kerve.co.uk/api/v1/game.lookup?identity=${lobbynumber}`)
        .then(response => {
          setPersonalisationDetails(response.data.data);
          setCurrentSpin(response.data.data.current_spin ? response.data.data.current_spin : 1);

          if (response.data.data.game_status === 'completed') {
            setGameStatus(response.data.data.game_status);
          }
        })
        .catch(e => {
          console.log(e);
        });
    }
  }, [lobbyId, lobbynumber, navigate, setCurrentSpin, setGameStatus, setHasJoinedRoom, setPersonalisationDetails, sharerid]);

  useEffect(() => {
    setUserDiconnection(false);

    if (lobbynumber) {
      setLobbyId(lobbynumber);
    }

    if (location.state && location.state.userGenerated === true) {
      setuserGenerateGame(true);
    }

    if (location.state && location.state.setAsHost === true) {
      setIsHost(true);
    }

    if (!hasJoinedRoom) {
      socket.emit('create_or_join', lobbyId);
    }
  }, [hasJoinedRoom, lobbyId, lobbynumber, location.state, setIsHost, setLobbyId, setUserDiconnection, setuserGenerateGame, socket]);

  useEffect(() => {
    socket.on('start_game', function () {
      navigate({
        pathname: sharerid ? `/game/i/${lobbynumber}/${sharerid}` : `/game/i/${lobbynumber}`
      });
    });
  }, [lobbyId, lobbynumber, navigate, sharerid, socket]);

  const handleGameStart = () => {
    socket.emit('request_start_game', lobbyId);
  };

  const handleDisconnect = () => {
    socket.emit('leave', lobbyId);
    setUserLeave(true);
  };

  const handleReconnect = () => {
    socket.connect();
    socket.emit('create_or_join', lobbyId);
    setUserDiconnection(false);
    setUserLeave(false);
    setLobbyFull(false);
  };

  const handleReplay = () => {
    socket.emit('leave', lobbyId);
    const newId = makeid(6);
    socket.emit('create_or_join', newId);
    setLobbyId(newId);

    console.log(shareLobby);
    if (shareLobby) {
      console.log('new game with share');
      navigate(`/i/${newId}/${shareLobby}`, {state: {userGenerated: true, setAsHost: true}});
    } else {
      navigate(`/i/${newId}`, {state: {userGenerated: true, setAsHost: true}});
    }
    setUserDiconnection(false);
    setUserLeave(false);
    setNewGameStatus(true);
    setGameStatus('free-play');
    setCurrentSpin(0);
  };

  if (window.innerHeight < window.innerWidth) {
    return (
      <motion.div className="app justify-center" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1}}>
        <p className="app__instructions">Sorry, please turn your screen to portrait to play.</p>
      </motion.div>
    );
  }

  if (gameStatus === 'completed' || (userGenerateGame && isHost && !newGameStatus) || !lobbyId) {
    return (
      <motion.div className="app justify-center" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1}}>
        <div className="app__main-content">
          <div className="app__logo-container">
            <p className="app__recipient">Kerve's</p>
            <img alt="logo" className="app__logo" src="/logo-white.svg"></img>
            <p className="app__logo-line-2">of</p>
            <p className="app__logo-line-3">christmas</p>
          </div>
          <p className="app__instructions">{'All the prizes are won, but you can still play Kerve’s Christmas game for fun.'}</p>
          <p onClick={() => handleReplay()} className="app__start-game">
            PLAY
          </p>
        </div>
      </motion.div>
    );
  }

  if (userDisconnection || userLeave) {
    return (
      <motion.div className="app justify-center" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1}}>
        <div className="app__main-content">
          <p className="app__instructions">{userLeave ? 'Thanks for playing, we hope you enjoyed our game.' : 'Sorry, you’ve been disconnected.'}</p>
          <p onClick={() => handleReconnect()} className="app__start-game">
            Rejoin
          </p>
        </div>
      </motion.div>
    );
  }

  if (lobbySize < 3 && !userDisconnection) {
    return (
      <motion.div className="app" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1}}>
        <div className="app__header">
          {/* <img alt="snowflake" src="/snowflake.svg"></img> */}
          <span onClick={() => handleDisconnect()} className="leave-button">
            Leave
          </span>
        </div>
        <div className="app__main-content">
          {!userGenerateGame && (
            <div className="app__logo-container">
              <p className="app__recipient">{personalisationDetails && personalisationDetails['first_name']}'s</p>
              <img alt="logo" className="app__logo" src="/logo-white.svg"></img>
              <p className="app__logo-line-2">of</p>
              <p className="app__logo-line-3">christmas</p>
            </div>
          )}
          {userGenerateGame && isHost && <QRCode size={150} bgColor={'#000'} fgColor={'#fff'} value={window.location.href} />}
          <p className="app__instructions">
            You need {3 - lobbySize} more player{lobbySize !== 2 ? 's' : ''} to scan THIS QR code.
          </p>
        </div>
        <div className="app__lobby-count">
          <div className="app__lobby-count-item">
            <img alt="tree1" src={lobbySize > 0 ? '/tree1.svg' : '/notree.svg'}></img>
            <p className={lobbySize > 0 ? 'red' : 'grey'}>{lobbySize > 0 ? 'READY' : 'WAITING..'}</p>
          </div>
          <div className="app__lobby-count-item">
            <img alt="tree2" src={lobbySize > 1 ? '/tree2.svg' : '/notree.svg'}></img>
            <p className={lobbySize > 1 ? 'gold' : 'grey'}>{lobbySize > 1 ? 'READY' : 'WAITING..'}</p>
          </div>
          <div className="app__lobby-count-item">
            <img alt="tree3" src={lobbySize > 2 ? '/tree3.svg' : '/notree.svg'}></img>
            <p className={lobbySize > 2 ? 'green' : 'grey'}>{lobbySize > 2 ? 'READY' : 'WAITING..'}</p>
          </div>
        </div>
      </motion.div>
    );
  }
  if (lobbySize === 3 && !lobbyFull && !userDisconnection) {
    return (
      <motion.div className="app" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1}}>
        <div className="app__header">
          {/* <img alt="snowflake" src="/snowflake.svg"></img> */}
          <span onClick={() => handleDisconnect()} className="leave-button">
            Leave
          </span>
        </div>
        <div>
          <p className="app__instructions">Please place your phones on the table next to each other.</p>
          <p className="app__instructions text-white mt-0">Your phone should be position {playerNumber}.</p>
        </div>
        <div className="app__lobby-devices">
          <img alt="phone" src={playerNumber === 1 ? '/phone_selected.svg' : '/phone_not_selected.svg'}></img>
          <img alt="phone" src={playerNumber === 2 ? '/phone_selected.svg' : '/phone_not_selected.svg'}></img>
          <img alt="phone" src={playerNumber === 3 ? '/phone_selected.svg' : '/phone_not_selected.svg'}></img>
        </div>
        <p onClick={() => handleGameStart()} className="app__start-game">
          ok, we’re ready
        </p>
      </motion.div>
    );
  } else if (lobbyFull && !userDisconnection) {
    return (
      <motion.div className="app" initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 1}}>
        <div className="app__header">
          {/* <img alt="snowflake" src="/snowflake.svg"></img> */}
          <span onClick={() => handleDisconnect()} className="leave-button">
            Leave
          </span>
        </div>
        <div className="app justify-center">
          <div className="app__logo-container">
            <p className="app__recipient">{personalisationDetails && personalisationDetails['first_name']}'s</p>
            <img alt="logo" className="app__logo" src="/logo-white.svg"></img>
            <p className="app__logo-line-2">of</p>
            <p className="app__logo-line-3">christmas</p>
          </div>
          {userGenerateGame && isHost && <QRCode size={150} bgColor={'#000'} fgColor={'#fff'} value={window.location.href} />}
          <p className="app__instructions">Sorry 3 people are already playing.</p>
        </div>
      </motion.div>
    );
  }
};
